import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Table,
} from "reactstrap";

import PaymentTransactionTableData from "./PaymentTransactionTableData";
import { Breadcrumbs } from "../../AbstractElements";
import { AxiosInterceptor2 } from "../../utils/axios-utils";
import TimelineTab from "./TimelineTab";
import DataTable from "react-data-table-component";
import { OrderHistoryColumns } from "../../Data/PaymentTransaction";
import Loader from "../../CommonElements/Loader";
import { useNavigate } from "react-router-dom";

const PaymentTransactionPageContain = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setsearchText] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("all");
  const navigate = useNavigate()
  // useEffect(() => {
  //   getServices(setServices);
  // }, []);

  /* const handlePageChange = page => {
    fetchUsers(page);
    setCurrentPage(page);
  }; */

  useEffect(() => {
    fetchUsers({ page: 1 });
    return () => {
      setData({}); // This worked for me
    };
  }, []);

  const handleSearch = (e, payload) => {
    e.preventDefault();
    fetchUsers({ searchText, paymentStatus });
  };

  const fetchUsers = async ({
    searchText = "",
    paymentStatus = "",
    page = 1,
    size = perPage,
  }) => {
    setLoading(true);

    const config = {
      params: { page: page, limit: size, searchText, paymentStatus },
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        hotelId: localStorage.getItem("hotelId") || "c102",
      },
    };
    console.log("congig >> ", config);
    try {
      const res = await AxiosInterceptor2.get(
        "getResPaymentTransactionV2",
        config
      );
      console.log("data >>> ", res.data.result.data[0]);
      setData(res.data.result.data[0].data);
      setTotalRows(
        res.data.result.data[0].metadata[0]
          ? res.data.result.data[0].metadata[0].total
          : 0
      );
      setLoading(false);
    } catch (error) {
      console.log("error:", error);
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchUsers({ page, searchText, paymentStatus });
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    fetchUsers({ page, size: newPerPage, searchText, paymentStatus });
    setPerPage(newPerPage);
  };

  const setValue = async (e, value) => {
    e.preventDefault();
    let paymentStatusTemp = null;
    paymentStatusTemp = value;
    setPaymentStatus(value);
    await fetchUsers({ paymentStatus: paymentStatusTemp, searchText });
  };

  const navigateTo360 = (rowData) => {
    console.log(rowData);
    return navigate(
      `/profile-360?e=${btoa(rowData.emailId || ""
      )}&i=${btoa(rowData?.reservationId || ""
      )}&linkedInUrl=${btoa(rowData?.linkedInUrl || ""
      )}&lN_slt_skip=${btoa(rowData.isLinkedInSelectionSkip || ""
      )}`
    );
  }

  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Payment Transaction" >
        <div className="social-tab p-0" style={{float:'right'}}>
          <form  onSubmit={(e)=>handleSearch(e)}>
            <InputGroup>
              <Input  className="form-control" type="text" onChange={(e) => setsearchText(e.target.value)} value={searchText} placeholder="Search......." /><span className="input-group-text"> <i className="fa fa-search"  onClick={(e)=>handleSearch(e)}></i></span>
            </InputGroup>
          </form>
        </div>
      </Breadcrumbs>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <TimelineTab
                handleSearch={handleSearch}
                changeSearchText={(e) => setsearchText(e.target.value)}
                changeTabCallback={(e, value) => setValue(e, value)}
                activeTab={paymentStatus}
                searchText={searchText}
              />
            <CardBody>
              <div className="order-history table-responsive">
                <DataTable
                  columns={OrderHistoryColumns}
                  data={data}
                  progressPending={loading}
                  progressComponent={<Loader />}
                  pagination
                  paginationRowsPerPageOptions={[10,15,20,25,30, 50, 100]}
                  paginationServer
                  paginationTotalRows={totalRows}
                  paginationDefaultPage={currentPage}
                  onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onRowClicked={navigateTo360}
                  searching={true}
                />
              </div>
            </CardBody>
            <style jsx global>{`
              .rdt_TableHeadRow .dgGdqu {
                min-width: 225px !important;
              }
              .order-history .rdt_Table .rdt_TableHead h6, .order-history .rdt_Table .rdt_TableHead .h6{
                text-align: center;  
              }
            `}</style>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default PaymentTransactionPageContain;
