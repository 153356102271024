import React, { Fragment, useState } from "react";
// import { AlertCircle, Clock, Image, Users } from 'react-feather';
import {
  Button,
  Card,
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import TimelineTab from "./TimelineTab";
import Select from 'react-select'
import SelectElement from "../../CommonElements/formelements/select";
import DatePicker from "react-datepicker";
import { Btn } from "../../AbstractElements";
import { getAllCheckIn } from "../../actions/axios-actions";
import { CloseIcon, EmptyRoundIcon, MinusCircleIcon, TickIcon } from "./DailyCheckinSvgIcons";
import { useDispatch } from "react-redux";
import { setPageStatus } from "./pagesSlice";

const options = [
  { value: 'arrivalDate', label: 'Arrival date' },
  { value: 'departureDate', label: 'Departure date' },
  { value: 'reservations.checkInTime', label: 'Check-In Time' },
  { value: 'reservations.checkOutTime', label: 'Check-Out Time' },
]
const sortOptions = [
  { value: 'asc', label: 'ASC' },
  { value: 'desc', label: 'DESC' },
]

const filterOptions = [
  { value : "all", label : "All"},
  { value : "Reserved", label : "Reserved"},
  { value : "In-house", label : "In-house"},
  { value : "Cancelled", label : "Cancelled"},
  { value : "Checked-out", label : "Checked-out"},
  { value : "No-Show", label : "No-Show"},
  // { value : "WaitList", label : "WaitList"},
  // { value : "removed", label : "removed"},
];
// Daily checkin Filter Option List
const dailyCheckInFltrOptn = [
  { value: null, label: "All" },
  {
    value: {fltrOptnKey:"isInHouse",fltrOptnValue:true },
    label: `In-House` ,
  },
  {
    value: {fltrOptnKey:"isReserved",fltrOptnValue:true },
    label: `Reserved` ,
  },

  {
    value: {fltrOptnKey:"isEligibleForShashiCash",fltrOptnValue:true },
    label: `Eligible For Shashi Cash` ,
  },
  {
    value: {fltrOptnKey:"isEligibleForShashiCash",fltrOptnValue:false },
    label:`Not Eligible For Shashi Cash` ,
  },

  {
    value: {fltrOptnKey:"shashiCashEnrollment",fltrOptnValue:true },
    label:`Enrolled in Shashi Cash`
  },
  {
    value: {fltrOptnKey:"shashiCashEnrollment",fltrOptnValue:false },
    label:`Not Enrolled in Shashi Cash`
  },

  {
    value: {fltrOptnKey:"appSetupStatus",fltrOptnValue:1 },
    label:`App Setup`
  },
  {
    value: { fltrOptnKey: "appSetupStatus", fltrOptnValue: 0 },
    label:`App Not Setup`
  },
  {
    value: { fltrOptnKey: "appSetupStatus", fltrOptnValue: -1 },
    label:`Not have Account`
  },
];

const ActiveTabs = ({
  myActiveTab,
  setMyActiveTab,
  handleSearch,
  changeSearchText,
  searchText,
  setSearchText,
  handlefieldChange,
  handleSortChange,
  handleResFilter,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  handleSubmit,
  loading,
  setCheckInData,
  setLoading,
  dateFormat,
  setMyTotalRows,
  myCurrentPage,
  myPerPage,

  dailyCheckInFltr, setDailyCheckInFltr
}) => {
  const dispatch = useDispatch();
  // const [dailyCheckInFltr, setDailyCheckInFltr] = useState({})
  
  const handleGetAllCheckIn = (dailyCheckInFltr) => {
    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,
      setMyTotalRows,
      myCurrentPage,
      myPerPage,
      dailyCheckInFltr
    );
  }

  const handleDailyCheckInFltr = (option) => {
    if (option?.value) {
      setDailyCheckInFltr(option?.value)
      handleGetAllCheckIn(option?.value)
    } else {
      setDailyCheckInFltr({})
      handleGetAllCheckIn({})
    }
  }

  return (
    <Fragment>
      <Col sm={12}>
        {/* <Card> */}
        <div className="social-tab">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={myActiveTab == "Reservations" ? "active" : ""}
                onClick={() => {
                  dispatch(setPageStatus(false));
                  setMyActiveTab("Reservations");
                }}
              >
                Reservations
              </NavLink>
            </NavItem>
            {/* <NavItem>
                <NavLink
                  className={myActiveTab == "All CheckIn" ? "active" : ""}
                  onClick={() => setMyActiveTab("All CheckIn")}
                >
                  Check-In Report
                </NavLink>
              </NavItem> */}
            <NavItem>
              <NavLink
                className={myActiveTab == "Daily CheckIn" ? "active" : ""}
                onClick={() => {
                  setSearchText("");
                  setMyActiveTab("Daily CheckIn");
                }}
              > 
                Daily Check-In
              </NavLink>
            </NavItem>
          </Nav>
          {myActiveTab == "Reservations" ? <div className="d-flex ">
              <div className="me-2">
                <SelectElement
                  label="Filter"
                  options={filterOptions}
                  onChange={handleResFilter}
                  defaultValue={filterOptions[0]}
                />
              </div>
            </div>
          : null}

          {/* { myActiveTab == "All CheckIn"
            ? <div className="d-flex ">
                <div className="social-tab p-t-0 ps-0 mb-3">
                  <Row className="mt-xs-3 mt-sm-2 mt-lg-0">
                    <Col>
                      <Label>{"From:"}</Label>

                      <DatePicker
                        className="form-control digits"
                        selected={startDate}
                        onChange={(update) => {
                          setStartDate(update);
                          setEndDate(null);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Col>
                    <Col>
                      <Label  className="sm-bgred">{"To:"}</Label>

                      <DatePicker
                        className="form-control digits"
                        selected={endDate}
                        minDate={startDate}
                        onChange={(update) => {
                          setEndDate(update);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Col>

                    <Col className="">
                      <label class="form-label">&nbsp;</label>
                      <Btn
                        attrBtn={{
                          className: "m-r-15 m-l-15 d-block rounded-0",
                          color: "secondary",
                          onClick: () => {
                            getAllCheckIn(
                              setCheckInData,
                              setLoading,
                              dateFormat(startDate),
                              dateFormat(endDate),
                              searchText,
                          
                              setMyTotalRows,
                              myCurrentPage,
                              myPerPage
                            );
                          },
                          disabled: endDate && !loading ? false : true,
                        }}
                      >
                        Get Data
                      </Btn>
                    </Col>
                  </Row>
                </div>
              </div>
            : null } */}

          { myActiveTab == "Daily CheckIn"
            ? <div className="d-flex ">
              <div className="social-tab p-t-0 ps-0 mb-3">
                <Row className="mt-xs-3 mt-sm-2 mt-lg-0">
                  <Col>
                    <Label>{"From:"}</Label>

                    <DatePicker
                      className="form-control digits rounded-0"
                      selected={startDate}
                      onChange={(update) => {
                        setStartDate(update);
                        setEndDate(null);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>
                  <Col>
                    <Label  className="sm-bgred">{"To:"}</Label>

                    <DatePicker
                      className="form-control digits rounded-0"
                      selected={endDate}
                      minDate={startDate}
                      onChange={(update) => {
                        setEndDate(update);
                      }}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </Col>

                  

                  <Col className="">
                    <label class="form-label">&nbsp;</label>
                    <Btn
                      attrBtn={{
                        className: "m-r-15 m-l-15 d-block rounded-0",
                        color: "secondary",
                        disabled: startDate && endDate && !loading ? false : true,
                        onClick: ()=>handleGetAllCheckIn(dailyCheckInFltr),
                      }}
                    >
                      Get Data
                    </Btn>
                  </Col>
                  <Col >
                    <SelectElement
                      label="Filter"
                      options={dailyCheckInFltrOptn}
                      onChange={handleDailyCheckInFltr}
                      defaultValue={dailyCheckInFltrOptn[0]}
                    />
                  </Col>
                </Row>
              </div>
            </div>
          : null }

          {/* <TimelineTab
              handleSearch={handleSearch}
              changeSearchText={changeSearchText}
              searchText={searchText}
            /> */}
          {/* {myActiveTab == "Reservations" ?<div className="d-flex ">
                <div className="me-2">
                  <SelectElement
                    label="Filter"
                    options = {options} 
                    onChange = {handlefieldChange}
                  />
                </div>
                <div className="me-2">
                  <SelectElement
                    label="Sort By"
                    options = {sortOptions} 
                    onChange = {handleSortChange}
                  />
                </div>
                <div>
                  <label>&nbsp;</label>
                  <div>
                    <Button className="rounded-0" onClick={handleSearch}>Filter</Button>
                  </div>
                </div>
            </div>:null} */}
        </div>
        {/* </Card> */}
      </Col>
    </Fragment>
  );
};
export default ActiveTabs;
