import React, { Fragment } from "react";
import { Card, Col, Row } from "reactstrap";
import { rgbToHex } from "../../../../helperFunctions/rgbToHexConverion";

const Preferences = (props) => {
  let { preferences } = props?.data;
  let lightColor;
  if (preferences && Array.isArray(preferences?.sleepQuality)) {
    preferences.lightColorHexCode = rgbToHex(preferences.lightColorRed, preferences.lightColorGreen, preferences.lightColorBlue);
    lightColor = {
      backgroundColor: `rgb(${preferences.lightColorRed}, ${preferences.lightColorGreen}, ${preferences.lightColorBlue})`
    };
    if(preferences.temperatureUnit?.toLowerCase()?.includes("f")) preferences.temperatureUnit = ` °F`;
    else if(preferences.temperatureUnit?.toLowerCase()?.includes("c")) preferences.temperatureUnit = ` °C`;
  }
  
  return preferences ? (
    <Fragment>
      <Row className="preferences">
        <Col className="column" sm="12" md="4">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Light Percentage</label>
                  <label className="text-title">{preferences.lightPercentage}%</label>
                </div>
              </Col>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Light Color Code</label>
                  <label className="text-title">{preferences.lightColorHexCode}</label>
                </div>
              </Col>
              <Col sm="6" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Light Color</label>
                  <div style={{...lightColor, height: "40px", width: "40px", borderRadius: "50%"}} />
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="4">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Temperature</label>
                  <label className="text-title">{`${preferences.temperature}${preferences.temperatureUnit}`}</label>
                </div>
              </Col>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Fan Speed</label>
                  <label className="text-title">{preferences.fanSpeed}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="4">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="12">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Music</label>
                  <label className="text-title">{preferences.musicFile}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="4">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="12">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Sleeping Essentials</label>
                  {
                    preferences.sleepQuality.map(val => {
                      return <label className="text-title">{val}</label>
                    })
                  }
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="4">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="12">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">White Noise</label>
                  <label className="text-title">{preferences.whiteNoiseForBetterSleep}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="4">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="12">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Calming Music</label>
                  <label className="text-title">{preferences.calmingMusic}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="4">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Pillow</label>
                  <label className="text-title">{preferences.pillowType}</label>
                </div>
              </Col>
              <Col sm="6">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Room Selection</label>
                  <label className="text-title">{preferences.roomSelection}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col className="column" sm="12" md="8">
          <Card className="p-4 inner-card">
            <Row>
              <Col sm="4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Wine Type</label>
                  <label className="text-title">{preferences.typeOfWine}</label>
                </div>
              </Col>
              <Col sm="4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Acidic Soft</label>
                  <label className="text-title">{preferences.acidic_soft}</label>
                </div>
              </Col>
              <Col sm="4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Dry Sweet</label>
                  <label className="text-title">{preferences.dry_sweet}</label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm="4" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Light Bold</label>
                  <label className="text-title">{preferences.light_bold}</label>
                </div>
              </Col>
              <Col sm="4" className="mt-4">
                <div className="d-flex flex-column line-height">
                  <label className="text-label">Smooth Tannic</label>
                  <label className="text-title">{preferences.smooth_tannic}</label>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Fragment>
  ) : (
    <div>No Preferences found.</div>
  );
};

export default Preferences;