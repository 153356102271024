import { Breadcrumbs, H3 } from '../../AbstractElements';
import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Container, Input, InputGroup, Row, Table } from 'reactstrap';
import DataTable from "react-data-table-component";
import Loader from "../../CommonElements/Loader";
import { OrderHistoryColumns } from "../../Data/MobileUser";
import { AxiosInterceptor, AxiosInterceptor2 } from "../../utils/axios-utils";
import TimelineTab from "./TimelineTab";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Form from "./detailpage/form";
import { useSelector } from "react-redux";
import { navbarSelector } from "../../Auth/navbarSlice";

const MobileUsersPageContain = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(false);

  const toggle = (_id) => {
    setModalData({_id})
    setModal(!modal);
  }

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState('');
  const [searchText, setsearchText] = useState('')
  const [tabStatus, setTabStatus] = useState('all')


  // let emailVerified = null;
  // let isUserProfileSetup = null;
  useEffect(() => {
    fetchUsers({page:1});
    return () => {
      setData({}); // This worked for me
    };
  }, []);

  const handleSearch = (e)=>{
    e.preventDefault();
    fetchUsers({searchText})
  }
  
  const { profileData, isLoading, status } = useSelector(navbarSelector);
  const fetchUsers = async ({searchText='', tabStatus='', page=1, size = perPage }) => {
    setLoading(true);

    const config = {
      params: { page: page, limit: size , searchText , tabStatus},
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
        hotelId: localStorage.getItem("hotelId") || "c102",
      },
    };
    // console.log("congig >> ",config);

    try {
      const res = await  AxiosInterceptor2.get("getAllUsersV2", config)
      // console.log("data >>> ",res.data.result);
      setData(res.data.result.data);
      setTotalRows(res.data.result.count);
      setLoading(false);
    } catch (error) {
        // console.log('error:', error)
        setLoading(false);
      
    }
  };

 
  const handlePageChange = (page) => {
    fetchUsers({page, searchText , tabStatus});
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    fetchUsers({page, size: newPerPage, searchText  , tabStatus});
    setPerPage(newPerPage);
  };

  const setValue = async (e,value) =>{
      e.preventDefault();
      let statusTemp = null;
      statusTemp = value
      setTabStatus(value)
      await fetchUsers({tabStatus:statusTemp, searchText} )

  }
  if(profileData && profileData.allowedPages){
    var pagePermissions = profileData.allowedPages.filter(o => o.path == '/mobile-users');
    var pagepermission = pagePermissions && pagePermissions[0]?pagePermissions[0]:false;
  }

  const navigateTo360 = (rowData) => {
    console.log(rowData,"rowData");
    return navigate(
      `/profile-360?e=${btoa(rowData.email || ""
      )}&i=&linkedInUrl=${btoa(rowData?.linkedInUrl || ""
      )}&lN_slt_skip=${btoa(rowData.isLinkedInSelectionSkip || ""
      )}`
    );
  }

  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Mobile Users" >
        <form  onSubmit={(e)=>handleSearch(e)} className='social-tab p-0' style={{float:'right'}}>
          <InputGroup>
            <Input  className="form-control" type="text" onChange={(e) => setsearchText(e.target.value)} value={searchText} placeholder="Search......." /><span className="input-group-text"> <i className="fa fa-search"  onClick={(e)=>handleSearch(e)}></i></span>
          </InputGroup>
        </form>
      </Breadcrumbs>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
                <TimelineTab
                  handleSearch={handleSearch}
                  changeSearchText={(e) => setsearchText(e.target.value)}
                  changeTabCallback={(e, value) => setValue(e, value)}
                  activeTab={tabStatus}
                  searchText={searchText}
                  />
              <CardBody>
                <div className="order-history table-responsive">
                  <DataTable
                    columns={OrderHistoryColumns}
                    data={data}
                    progressPending={loading}
                    progressComponent={<Loader/>}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={currentPage}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    searching={true}
                    paginationRowsPerPageOptions={[10,15,20,25,30, 50, 100]}
                    pointerOnHover
                    onRowClicked={navigateTo360
                      // (clickedItem) => {
                      //   if(pagepermission && pagepermission.update){
                      //         if(clickedItem.isExpressCheckInSetup == false){
                      //          toggle(clickedItem._id)
                      //          }
                      //        }
                      //  }
                  }
                  />
                </div>
              </CardBody>
              {modal?<Form
                modal={modal}
                toggle={toggle}
                modalData={modalData}
                onCallBack={()=>{
                  fetchUsers({page:currentPage,perPage,searchText,tabStatus})
                  // setModalData(false)
                }}
              />:null}
              <style jsx global>{`
                  .order-history .rdt_Table .rdt_TableHead h6, .order-history .rdt_Table .rdt_TableHead .h6{
                    text-align: center;  
                  }
                `}</style>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default MobileUsersPageContain;