import {
  CurrentReservation,
  Folio,
  KeyGen,
  Notification,
  PastReservation,
  Preferences,
} from "./navTabComponents";
export const NavLinks = [
  {
    link: "current-reservation",
    title: "current reservation",
    Component: CurrentReservation,
  },
  // {
  //   link: "past-reservation",
  //   title: "past reservation",
  //   Component: PastReservation,
  // },
  {
    link: "key-generation",
    title: "key generation",
    Component: KeyGen,
  },
  {
    link: "notification",
    title: "notification",
    Component: Notification,
  },
  {
    link: "folio",
    title: "folio",
    Component: Folio,
  },
  {
    link: "preferences",
    title: "preferences",
    Component: Preferences,
  }
];
