import { inventoryThunk } from "../../Component/InventoryManagement/js/api";
import IotDashboardComponent from "../../Component/IotDashboardComponent";
import { Breadcrumbs } from "../../AbstractElements";
import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

function IotDashboard() {
  // const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(inventoryThunk.allMeetingCategroy());
  // }, []);

  return (
    <Fragment>
      <IotDashboardComponent />
    </Fragment>
  );
}

export default IotDashboard;
