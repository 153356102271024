import React, { Fragment } from "react";
import "./inventoryManament.css";
import SelectMeetingCategory from "./selectMeetingCategory";
import BookingModal from "./bookingModal";
import CustomWrapper from "./customWrapper";
function InventoryManagement() {
  return (
    <Fragment>
      <SelectMeetingCategory />
      <CustomWrapper />
      <BookingModal />
    </Fragment>
  );
}

export default InventoryManagement;
