import { inventoryThunk } from "../../Component/InventoryManagement/js/api";
import InventoryManagement from "../../Component/InventoryManagement";
import { Breadcrumbs } from "../../AbstractElements";
import React, { Fragment, useEffect } from "react";
import { useDispatch } from "react-redux";

function InventoryMangementPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(inventoryThunk.allMeetingCategroy());
  }, []);

  return (
    <Fragment>
      <InventoryManagement />
    </Fragment>
  );
}

export default InventoryMangementPage;
