import React, { Fragment, useState } from "react";
import { Card, CardHeader, Col, Container, Row } from "reactstrap";
import { H5 } from "../../AbstractElements";
import {
  getAllCheckIn,
  getMobileAppReservastions,
} from "../../actions/axios-actions";
import OrderTableData from "./ReservationTableData";
import Breadcrumbs from "../../CommonElements/Breadcrumbs";
import TimelineTab from "./TimelineTab";
import ActiveTabs from "./ActiveTabs";
import AllCheckIn from "./AllCheckIn";
import { useSelector } from "react-redux";
import { navbarSelector } from "../../Auth/navbarSlice";
import DailyCheckIn from "./DailyCheckIn";
import momentTZ from 'moment-timezone';
import { pagesSelector } from "./pagesSlice";

export const dateFormat = (data) => {
  // "Fri May 26 2023 14:43:45 GMT+0530 (India Standard Time)"
  // console.log("data:", data);

  const inputDate = new Date(data);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const convertedDate = inputDate.toLocaleDateString("en-US", options);
  // 05/26/2023
  // console.log(convertedDate);
  

  const parts = convertedDate.split("/");
  const formattedDate = `${parts[2]}-${parts[0]}-${parts[1]}`;

  // 2023-26-05
  // console.log(formattedDate);

  return formattedDate;
};

const MobileAppReservationContain = () => {
  let { redirectedFromDailyCheckinTable } = useSelector(pagesSelector);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [myActiveTab, setMyActiveTab] = useState(redirectedFromDailyCheckinTable ? "Daily CheckIn" : "Reservations");
  const [checkInData, setCheckInData] = useState([]);
  const [myTotalRows, setMyTotalRows] = useState(0);
  const [myPerPage, setMyPerPage] = useState(10);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(momentTZ().tz("US/Pacific").startOf('day'))
  );
  const [endDate, setEndDate] = useState(
    new Date(momentTZ().tz("US/Pacific").startOf('day'))
  );
  const [fieldSelected, setFieldSelected] = useState(false);
  const [sortSelected, setSortSelected] = useState(false);
  const [filterSelected, setFilterSelected] = useState(false);
  const [dailyCheckInFltr, setDailyCheckInFltr] = useState({})


  let OrderTableDataProps = {
    data,
    setData,
    loading,
    setLoading,
    totalRows,
    setTotalRows,
    perPage,
    setPerPage,
    currentPage,
    setCurrentPage,
    searchText,
    setSearchText,
  };

  let AllCheckInDataProps = {
    checkInData,
    setCheckInData,
    loading,
    setLoading,
    searchText,
    setSearchText,
    myTotalRows,
    setMyTotalRows,
    myPerPage,
    setMyPerPage,
    myCurrentPage,
    setMyCurrentPage,
    startDate,
    setStartDate,
    endDate,
    setEndDate,dailyCheckInFltr,setDailyCheckInFltr


  };

  const handleSearch = (e) => {
    e.preventDefault();
    // console.log("searchText:", searchText);

    getMobileAppReservastions(setData, setLoading, setTotalRows, {
      searchText,
      fieldSelected,
      sortSelected,
      size: perPage,
      inforStatus: filterSelected ? filterSelected.value : false,
    });
  };
  const handleResFilter = (filterObj) => {
    console.log(filterObj);
    setFilterSelected(filterObj);
    getMobileAppReservastions(setData, setLoading, setTotalRows, {
      searchText,
      fieldSelected,
      sortSelected,
      inforStatus : filterObj.value
    });
  }
  const myhandleSearch = (e) => {
    e.preventDefault();
    // console.log("mySearchText:", searchText);

    getAllCheckIn(
      setCheckInData,
      setLoading,
      dateFormat(startDate),
      dateFormat(endDate),
      searchText,
      setMyTotalRows,
      myCurrentPage,
      myPerPage
    );
  };
  const handlefieldChange = (option) => {
    // console.log(option);
    setFieldSelected(option.value)
  }
  const handleSortChange = (option) => {
    // console.log(option)


    setSortSelected(option.value, );
  }
  return (
    <Fragment>
      <Breadcrumbs parent="Pages" title="Reservations">
      <TimelineTab
          handleSearch={myActiveTab == "Reservations"
          ? handleSearch
          : myhandleSearch}
          changeSearchText={(e) => setSearchText(e.target.value)}
          searchText={searchText}
        />
      </Breadcrumbs>
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              {/* <CardHeader className="pb-0">
                <H5>Mobile App Reservations</H5>
              </CardHeader> */}

              {/* <CardHeader className="pb-0"> */}
                <ActiveTabs
                  myActiveTab={myActiveTab}
                  setMyActiveTab={setMyActiveTab}
                  //
                  handleSearch={
                    myActiveTab == "Reservations"
                      ? handleSearch
                      : myhandleSearch
                  }
                  changeSearchText={(e) => setSearchText(e.target.value)}
                  searchText={searchText}
                  setSearchText={setSearchText}
                  handlefieldChange={handlefieldChange}
                  handleSortChange={handleSortChange}
                  handleResFilter={handleResFilter}
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  loading={loading}
                  setLoading={setLoading}
                  setCheckInData={setCheckInData}
                  dateFormat={dateFormat}
                  setMyTotalRows={setMyTotalRows}
                  myCurrentPage={myCurrentPage}
                  myPerPage={myPerPage}

                  dailyCheckInFltr={dailyCheckInFltr}
                  setDailyCheckInFltr={setDailyCheckInFltr}
                />
              {/* </CardHeader> */}

              {myActiveTab == "Reservations" ? (
                <OrderTableData {...OrderTableDataProps} inforStatus={filterSelected?filterSelected.value:false}/>
                ) : myActiveTab == "All CheckIn" ? (
                  <AllCheckIn {...AllCheckInDataProps} />
                ) : (
                  <DailyCheckIn {...AllCheckInDataProps} />
                )
              }
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
export default MobileAppReservationContain;
