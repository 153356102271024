import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    redirectedFromDailyCheckinTable: false
};

const pagesSlice = createSlice({
  initialState,
  name: "pagesSlice",
  reducers: {
    setPageStatus: (state, action) => {
        state.redirectedFromDailyCheckinTable = action.payload;
    }
  }
});

export const { setPageStatus } = pagesSlice.actions;
export default pagesSlice.reducer;
export const pagesSelector = (state) => state.pagesSlice;