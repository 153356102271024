import React, { Fragment } from "react";
import { selectedMeetingCategorySelector } from "./js/slice";
import EventCalendar from "./eventCalender";
import { useSelector } from "react-redux";

function CustomWrapper() {
  const selectedMeetingCategory = useSelector(selectedMeetingCategorySelector);
  if (!selectedMeetingCategory) return <Fragment />;
  return <EventCalendar selectedMeetingPorts={selectedMeetingCategory} />;
}

export default CustomWrapper;
