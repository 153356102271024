export const reducers = {
  selectMeetingCategory: (state, action) => {
    state.selectedMeetingCategory = action.payload;
  },
  selectSlotSteps: (state, action) => {
    state.selectedSlotSteps = action.payload;
  },
  openModal: (state) => {
    state.modal = true;
  },
  closeModal: (state) => {
    state.modal = false;
  },
  closeModal: (state) => {
    state.modal = false;
  },
  setModalFormData: (state, action) => {
    state.formData.title = "";
    state.formData.email = "";
    state.formData.categoryName = action.payload.categoryName;
    state.formData.meetingRoom = action.payload.meetingRoom;
    state.formData.hotelId = action.payload.hotelId;
    state.formData.start = action.payload.start;
    state.formData.end = action.payload.end;
    state.formData.slots = action.payload.slots;
    state.formData.bookedDate = action.payload.bookedDate;
    
  },
  setAllBookedMeetings: (state, action) => {
    state.allBookedMeetings = action.payload;
  }
};
