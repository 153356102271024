import moment from "moment";
import * as Yup from "yup";

let inputArray = [
  { type: "text", name: "title", placeholder: "Meeting Title" },
  { type: "email", name: "email", placeholder: "Email" },
  {
    readOnly: true,
    type: "text",
    name: "categoryName",
    placeholder: "Category Name",
    className: "cursor-not-allowed",
  },
  {
    readOnly: true,
    type: "text",
    name: "meetingRoom",
    placeholder: "Meeting Room",
    className: "cursor-not-allowed",
  },
];
const meetingBookingSchema = Yup.object().shape({
  title: Yup.string().required("Title is a required field"),
  email: Yup.string().required("Email is a required field"),
  categoryName: Yup.string().required(),
  meetingRoom: Yup.string().required(),
  hotelId: Yup.string().required(),
  start: Yup.string().required(),
  end: Yup.string().required(),
  slots: Yup.array().required(),
  bookedDate: Yup.string().required(),
});

const modalBtnStyle = {
  color: "white",
  border: "none",
  width: "250px",
  paddingTop: "9px",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: "4px",
  paddingBottom: "9px",
  background: "black",
  display: "flex",
  justifyContent: "center",
};

const generateTimeSlots = (start, end, slotDifferenceMinutes) => {
  if (!start || !end || !slotDifferenceMinutes) return [];
  const startTime = moment(start);
  const endTime = moment(end);
  let currentTime = moment(startTime);
  const slots = [];
  while (currentTime.isBefore(endTime)) {
    const nextTime = currentTime.clone().add(slotDifferenceMinutes, "minutes");
    const slotEndTime = nextTime.isBefore(endTime) ? nextTime : endTime;
    const slotStr = `${currentTime.format("HH:mm:ss")} to ${slotEndTime.format(
      "HH:mm:ss"
    )}`;
    slots.push(slotStr);
    currentTime = nextTime;
  }
  return slots;
};

export const inventoryHelper = {
  inputArray,
  meetingBookingSchema,
  modalBtnStyle,
  generateTimeSlots,
};
