import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Card, Input, Modal } from "reactstrap";
import { inventoryAction, inventorySelector } from "./js/slice";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { inventoryHelper } from "./js/helper";
import { inventoryThunk } from "./js/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ThreeDots } from "react-loader-spinner";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

function BookingModal() {
  const selector = useSelector(inventorySelector);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: selector.formData,
    enableReinitialize: true,
    validationSchema: inventoryHelper.meetingBookingSchema,
    onSubmit: (e) => {
      dispatch(inventoryThunk.createNewMeeting(e));
    },
  });
  const Button = ({ loader }) => {
    return loader ? (
      <div style={inventoryHelper.modalBtnStyle}>
        <ThreeDots
          height="16"
          width="35"
          radius="9"
          color="#ffff"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
    ) : (
      <button style={inventoryHelper.modalBtnStyle} type="submit">
        Submit
      </button>
    );
  };

  return (
    <Modal
      centered={true}
      className="inventory-modal"
      isOpen={selector.modal}
      toggle={() => dispatch(inventoryAction.closeModal())}
    >
      <div style={{ paddingTop: "20px", paddingBottom: "20px", width: "100%" }}>
        <div
          style={{ position: "absolute",top:0, right: 0, marginRight: "20px",marginTop:'20px' }}
          onClick={() => dispatch(inventoryAction.closeModal())}
        >
          <FontAwesomeIcon cursor={'pointer'} color="black" size="xl" icon={faXmark} />
        </div>
       
        <h3 style={{ marginBottom: "25px",marginTop:'20px' }}>Booking Details</h3>
       
        <form
          style={{ marginLeft: "20px", marginRight: "20px" }}
          onSubmit={formik.handleSubmit}
        >
          {inventoryHelper?.inputArray.map((e, indx) => (
            <Fragment key={indx}>
              <Input
                {...e}
                value={formik.values[e.name]}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched[e.name] && formik.errors[e.name] ? (
                <div
                  style={{ height: "20px", fontSize: "11px", color: "#ba0000" }}
                >
                  {formik.errors[e.name]}
                </div>
              ) : (
                <div style={{ height: "20px" }}></div>
              )}
            </Fragment>
          ))}
          <Button loader={selector?.loader} />
        </form>
      </div>
    </Modal>
  );
}

export default BookingModal;
