import React from "react";
import {
  availSlotStepsSelector,
  inventoryAction,
  meetingCategorySelector,
  selectedMeetingCategorySelector,
  selectedSlotStepsSelector,
} from "./js/slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { H3 } from "../../AbstractElements";
import SelectElement from "../../CommonElements/formelements/select";

function SelectMeetingCategory() {
  const dispatch = useDispatch();
  const meetingCategory = useSelector(meetingCategorySelector);
  const selectedMeetingCategory = useSelector(selectedMeetingCategorySelector);
  const availSlotSteps = useSelector(availSlotStepsSelector);
  const selectedSlotSteps = useSelector(selectedSlotStepsSelector);

  const handleChange = (e) => {
    dispatch(inventoryAction.selectMeetingCategory(e));
  };
  const handleChangeSlot = (e) => {
    dispatch(inventoryAction.selectSlotSteps(e));
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "30px",
        flexWrap: "wrap",
        flexDirection: "row",
      }}
    >
      <div className="page-title inventory-title">
          <H3>Inventory Management</H3>
      </div>
      {meetingCategory?.length > 0 && (
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
        >
          {selectedMeetingCategory?.categoryName == "Meeting Pods" && (
            <div
              style={{
                marginTop: "auto",
                width: "200px",
                marginLeft: "15px",
              }}
            >
              <SelectElement
                defaultValue={selectedSlotSteps}
                label="Slot steps"
                options={availSlotSteps}
                onChange={handleChangeSlot}
              />
            </div>
          )}
          <div
            style={{
              marginTop: "auto",
              width: "200px",
              marginRight: "15px",
              marginLeft: "15px",
            }}
          >
            <SelectElement
              defaultValue={selectedMeetingCategory}
              label="Meeting Category"
              options={meetingCategory}
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectMeetingCategory;
