import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInterceptor2 } from "../../../utils/axios-utils";
import { inventoryAction } from "./slice";

const allMeetingCategroy = createAsyncThunk(
  "inventory/findAllMeetingCategory",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let res = await AxiosInterceptor2.get(
      "/inventory-management/meeting-category"
    );
    if (res.data?.success) {
      return res.data?.data;
    } else {
      throw rejectWithValue("failed");
    }
  }
);

const createNewMeeting = createAsyncThunk(
  "inventory/createNewMeeting",
  async (state, { getState, rejectWithValue, dispatch }) => {
    try {
      const { ManageInventory } = getState();
      let res = await AxiosInterceptor2.post(
        "/inventory-management/meetings",
        state
      );
      dispatch(inventoryAction.closeModal());
      if (res.data?.success) {
        dispatch(findMeeting(ManageInventory.formData));
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error.response.data.message)
      }
      
      throw rejectWithValue(error);
    }
  }
);

const cancelMeeting = createAsyncThunk(
  "inventory/cancelMeeting",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let res = await AxiosInterceptor2.put(
      `/inventory-management/cancel-meeting/${state._id}`
    );
    if (res.data?.success) {
      dispatch(findMeeting(state));
      return res.data.data;
    } else {
      throw rejectWithValue("failed");
    }
  }
);

const findMeeting = createAsyncThunk(
  "inventory/findMeeting",
  async (state, { getState, rejectWithValue, dispatch }) => {
    try {
      let res = await AxiosInterceptor2.get("/inventory-management/meetings", {
        params: { categoryName: state?.categoryName },
      });
      if (res.data?.success) {
        return res.data;
      } 
    } catch (error) {
      if (error?.response?.data?.message) {
        alert(error.response.data.message)
      }
      
      throw rejectWithValue(error);
    }
  }
);

export const inventoryThunk = {
  allMeetingCategroy,
  createNewMeeting,
  findMeeting,
  cancelMeeting,
};
