import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  Input,
  FormGroup
} from "reactstrap";
import { CloseIcon, OpenIcon, Warning } from "../../Data/Icons";
import IotModal from "../../_core/Ui-kits/Modals/common/IotModal";
import { getIotDashboardData, getSpecificroomAvailability, updateSwitchStatus } from "../../actions/axios-actions";
import Loader from "../../CommonElements/Loader";
import { AxiosInterceptor2 } from "../../utils/axios-utils";
import styles from "./MyComponent.module.css";
import { toast } from "react-toastify";
import { io } from "socket.io-client";
import { config } from "../../config";
import moment from "moment";

const Home = () => {
  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [rooms, setRooms] = useState([]);
  const [modalData, setModalData] = useState({});
  const [isDisableBtn, setIsDisableBtn] = useState(false);
  const [notifyRooms, setNotifyRooms] = useState([]);
  const [socket, setSocket] = useState(null);
  const [sendNotification, setSendNotification] = useState(0);
  const [visibleFloors, setVisibleFloors] = useState({
    2: true,
    3: true,
    4: true,
    5: true,
  });
  const [roomType, setRoomType] = useState();
  const handleModal = () => setIsModal(!isModal);
  const [modelLoading, setModelLoading] = useState(false);

  const handleSwitchToggle = async (deviceId, currentStatus) => {
    try {
      const newStatus = !currentStatus;

      // Optimistically update the UI
      const updateState = (prevState) => ({
        ...prevState,
        data: prevState.data.map(device =>
          device.deviceID === deviceId
            ? { ...device, isSwitchOff: newStatus }
            : device
        )
      });

      setModalData(updateState);
      setRooms(prevRooms => prevRooms.map(room =>
        room.roomNo === modalData.roomNo ? updateState(room) : room
      ));

      // Call the API to update the switch status
      const response = await updateSwitchStatus(deviceId, currentStatus);

      // If the API call fails, revert the UI state
      if (!response.data.success) {
        throw new Error('API failed to update switch status');
      }

      // Optionally, re-fetch the updated data from the server
      // const updatedData = await getSpecificroomAvailability(...);
      // setModalData(updatedData);

    } catch (error) {
      console.error('Error updating switch status:', error);

      // Revert the UI state to the previous value in case of an error
      const revertState = (prevState) => ({
        ...prevState,
        data: prevState.data.map(device =>
          device.deviceID === deviceId
            ? { ...device, isSwitchOff: currentStatus }
            : device
        )
      });

      setModalData(revertState);
      setRooms(prevRooms => prevRooms.map(room =>
        room.roomNo === modalData.roomNo ? revertState(room) : room
      ));


      // Show an error notification
      toast.error('Failed to update switch status. Please try again.');
    }
  };


  useEffect(() => {
    let notifyRoomsResult = localStorage.getItem("notificationRooms") ? JSON.parse(localStorage.getItem("notificationRooms")) : [];
    const set = new Set(notifyRoomsResult ?? []);
    console.log(...set)
    setNotifyRooms((prev) => { return [...prev, ...set] });
  }, []);

  useEffect(() => {
    fetchFunctions();
    const interval = setInterval(() => {
      fetchFunctionsRecursively();
    }, 120000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (socket === null) {
      setSocket(io(config.RESERVATION_API_HOST));
    }

    if (socket) {
      // Check if socket is defined and start listening to events
      socket.on("connect", () => {
        console.log("Connected Inside");
      });

      // Listening for "refresh_iot" event
      socket.on("refresh_iot", function (data) {
        setSendNotification((prev) => {
          return prev++;
        })
        console.log("refresh_iot", data);
      });
    }
    if (socket) {
      socket.on("connect_error", (err) => {
        console.error("Connection error: ", err);
      });
    }

    // Cleanup function: executed when the component unmounts or dependencies change
    return () => {
      if (socket) {
        console.log('Cleaning up socket listeners...');
        socket.off("refresh_iot"); // Turn off the "refresh_iot" event
        console.log('Socket listeners cleaned up');
      }
    };
  }, [socket]);


  const fetchFunctions = async () => {
    !isDisableBtn && setLoading(true);

    try {
      let res = await getIotDashboardData(cb);
      // console.log(res.data?.result);
      // setRooms(res.data?.result);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFunctionsRecursively = async () => {
    try {
      console.log("RECURSIVE")
      let res = await getIotDashboardData(cb);
      // console.log(res.data?.result);
      // setRooms(res.data?.result);
      setError(null)
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    rooms.forEach((room) => {
      const { roomNo, isAnyOffline } = room;

      setNotifyRooms((prevNotifyRooms) => {
        const updatedRooms = new Set(prevNotifyRooms);
        if (isAnyOffline && !updatedRooms.has(roomNo)) {
          // Show notification and add room to notifyRooms
          toast(
            <div className="d-flex">
              Attention: The Devices in Room {roomNo} have gone offline
            </div>,
            {
              position: "top-right",
              autoClose: false,
              hideProgressBar: false,
              progressClassName: "bg-white",
              progressStyle: { background: "#FFFFFF" },
              style: { background: "#FFF", fontSize: "16px", fontFamily: "Azo Sans" },
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              theme: "light",
              className: "bg-black text-white",
            }
          );
          updatedRooms.add(roomNo);
        } else if (!isAnyOffline && updatedRooms.has(roomNo)) {
          updatedRooms.delete(roomNo);
        }

        // Update localStorage
        localStorage.setItem("notificationRooms", JSON.stringify([...updatedRooms]));
        return updatedRooms;
      });
    });
  }, [sendNotification]);

  let cb = (data) => {
    console.log("data:", data);
    if (data.success) {
      setLoading(false);
      setRooms(data?.result);
    } else {
      console.log("heheh data.error:", data);
      setError({
        message: data?.response?.data?.result?.message,
        success: false,
      });
      setLoading(false);
    }
  };

  let rType = "";
  switch (modalData.roomType) {
    case "PK":
      rType = "Premium King";
      break;
    case "DK":
      rType = "Delux King";
      break;
    case "DKA":
      rType = "Deluxe King - Accessible";
      break;
    case "PKA":
      rType = "Premium King - Accessible";
      break;
    default:
      rType = modalData.roomType;
      break;
  }

  let rValue = "";
  switch (roomType) {
    case "Available":
      rValue = "Vacant";
      break;
    case "INSPCT":
      rValue = "Inspect";
      break;
    case "DIRTY":
      rValue = "Dirty";
      break;
    default:
      rValue = "In-House";
      break;
  }
  const getlatestDevicesData = async () => {
    try {
      var startTime = performance.now();
      setIsDisableBtn(true);
      let updateTuyaDeviceIsOnlineStateDB = await AxiosInterceptor2.get(
        "updateTuyaDeviceIsOnlineStateDB"
      );
      console.log(
        "updateTuyaDeviceIsOnlineStateDB.data.sucess:",
        updateTuyaDeviceIsOnlineStateDB.data.success
      );

      let updateTuyaSwitchesStatusDB = await AxiosInterceptor2.get(
        "updateTuyaSwitchesStatusDB"
      );
      console.log(
        "updateTuyaSwitchesStatusDB.data.sucess:",
        updateTuyaSwitchesStatusDB.data.success
      );

      let res = await fetchFunctions();
      setIsDisableBtn(false);
      var endTime = performance.now();
      console.log(
        `Call to doSomething took ${endTime - startTime} milliseconds`
      );
      console.log("Data Updated");
    } catch (error) {
      setIsDisableBtn(false);
      console.log("getlatestDevicesData:", error);
      return toast.error(
        error.response?.data?.result?.message || "Oppss.. Something went wrong."
      );
    }
  };

  // Function to get the ordinal suffix for the floor number
  const getFloorLabel = (floor) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const remainder = floor % 10;
    const suffix =
      remainder > 3 || remainder === 0 ? suffixes[0] : suffixes[remainder];
    return `${floor}${suffix}`;
  };

  // Toggle visibility of a specific floor
  const handleVisibility = (floor) => {
    setVisibleFloors((prevState) => ({
      ...prevState,
      [floor]: !prevState[floor],
    }));
  };

  const floors = [2, 3, 4, 5];
  const Msg = ({ children }) => (
    <div className="d-flex">
      {children}
    </div>
  );
  // const setUniqueRoomNumbers = (room) => {
  //   const { roomNo, isAnyOffline } = room;

  //   if (!notifyRooms.includes(roomNo) && isAnyOffline) {
  //     toast.error(<Msg>Attention: The Devices in Room {roomNo} have gone offline </Msg>, {
  //       position: "top-right",
  //       autoClose: false,
  //       hideProgressBar: false,
  //       progressClassName: "bg-white",
  //       progressStyle: { background: "#FFFFFF" },
  //       style: {
  //         background: "#FFF",
  //         fontSize: '16px',
  //         fontFamily: 'Azo Sans'
  //       },
  //       closeOnClick: (e) => {console.log(e); return true },
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "light",
  //       className: "bg-black text-white",
  //     });
  //   }else{

  //     setNotifyRooms((prev) => {
  //       const updatedRooms = [...new Set(prev.filter((roomNumber) => roomNumber !== roomNo))];
  //       localStorage.setItem("notificationRooms", JSON.stringify([...updatedRooms]));
  //       return updatedRooms;
  //     });

  //   }

  // }
  return (
    <>
      <Container fluid className="mt-5">
        {loading && !error && (
          <div className="pt-5 mt-5">
            <Loader />
          </div>
        )}
      </Container>

      {/* Modal Open On Click Event */}
      {isModal && (
        <IotModal
          isOpen={isModal}
          title={modalData.roomNo}
          title2={rType}
          title3={modelLoading ? "Loading..." : rValue}
          toggler={handleModal}
          disabledButtombuttons={true}
        >
          {modalData.data?.map((device) => (
            <ul key={device.deviceID}>
              <li className="d-flex align-items-center justify-content-between p-2">
                <span className="text-start" style={{ flex: 1 }}>
                  {device.deviceName.trim().toLowerCase().includes("rm ") ||
                    device.deviceName.trim().toLowerCase().includes("room")
                    ? device.deviceName.split(" ").slice(2).join(" ")
                    : device.deviceName}
                </span>

                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {device.deviceName.toLowerCase().includes("switch") && (
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <span style={{ textAlign: 'left', color: device.isSwitchOff ? 'red' : 'gray' }}>OFF</span>
                      <FormGroup switch style={{ display: 'flex', justifyContent: 'center' }}>
                        <Input
                          type="switch"
                          role="switch"
                          checked={!device.isSwitchOff}
                          onChange={() => handleSwitchToggle(device.deviceID, device.isSwitchOff)}
                          style={{
                            fontSize: "15px",
                            backgroundColor: device.isSwitchOff ? "rgba(255, 23, 10, 1)" : "rgba(52, 199, 89, 1)",
                            padding: "6px",
                            color: 'white',
                          }}
                          className="switch-toggle-btn-white"
                        />
                      </FormGroup>
                      <span style={{textAlign: 'right', color: device.isSwitchOff ? 'gray' : 'green' }}>ON</span>
                    </div>
                  )}
                </div>

                <span className="text-danger" style={{ flex: 1, textAlign: 'right' }}>
                  <span className="d-flex align-items-center justify-content-end">
                    {device.offline ? (
                      <>
                        <CloseIcon className="me-2" />
                        <span className="text-danger mt-1">&nbsp;Disconnected</span>
                      </>
                    ) : (
                      <>
                        <OpenIcon className="me-2" />
                        <span className="text-success mt-1">&nbsp;Connected&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                      </>
                    )}
                  </span>
                </span>
              </li>
            </ul>
          ))}
        </IotModal>
      )}

      {/* Display the rows */}
      {!loading && !error && (
        <Container fluid className="pt-4">
          <Row className={styles.bbb}>
            <Col md="9" className="">
              {floors.map((floor) => (
                <div key={floor}>
                  <div
                    onClick={() => handleVisibility(floor)}
                    className="d-flex align-items-center justify-content-center cursor-pointer"
                    style={{
                      backgroundColor: "#0000001A",
                      height: "40px",
                      cursor: "pointer",
                      marginBottom: "24px",
                    }}
                  >
                    <h2 className="fw-bolder fs-20px text-center mb-0 mt-1">
                      {" "}
                      {getFloorLabel(floor)} Floor{" "}
                    </h2>
                  </div>

                  {visibleFloors[floor] && (
                    <Row style={{ marginTop: "24px" }}>
                      {[...rooms]
                        .sort((a, b) => a.roomNo - b.roomNo)
                        .filter(
                          (room) =>
                            room.roomNo &&
                            room.roomNo.toString().startsWith(floor.toString())
                        )
                        .map((room) => (
                          <Col key={room.roomNo} xs="1">
                            <Card
                              style={{ marginBottom: "24px" }}
                              className={
                                room.isAnyOffline
                                  ? "border bg-danger text-white rounded-0"
                                  : "border rounded-0 "
                              }
                            >
                              <CardBody
                                className="d-flex justify-content-center align-items-center p-2 mb-0  h3 m-0 border"
                                onClick={async () => {
                                  setModelLoading(true);
                                  let startDate = moment()
                                    .tz("US/Pacific")
                                    .format("YYYY-MM-DD");
                                  let endDate = moment()
                                    .tz("US/Pacific")
                                    .add(1, "day")
                                    .format("YYYY-MM-DD");
                                  let roomType = room.roomType;
                                  let roomNos = room.roomNo;
                                  console.log(
                                    roomType,
                                    roomNos,
                                    startDate,
                                    endDate
                                  );
                                  handleModal();
                                  setModalData(room);
                                  let res = await getSpecificroomAvailability(
                                    startDate,
                                    endDate,
                                    roomType,
                                    roomNos
                                  );
                                  setModelLoading(false);
                                  console.log(
                                    res?.result?.data?.data?.[0]?.hskpStatus
                                  );
                                  setRoomType(
                                    res?.result?.data?.data?.[0]?.hskpStatus
                                  );
                                }}
                              >
                                <span
                                  className={
                                    room.isAnyOffline ? "text-white" : ""
                                  }
                                >
                                  {room.roomNo}
                                </span>
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                    </Row>
                  )}
                </div>
              ))}
            </Col>

            <Col md="3">
              <div className="d-flex justify-content-end">
                <button
                  style={{
                    height: "40px",
                  }}
                  className={` ${isDisableBtn ? styles.btnDisabled : styles.btnOutlined}`}
                  disabled={isDisableBtn}
                  onClick={() => getlatestDevicesData(true)}
                >
                  {isDisableBtn ? "Please Wait ..." : "Get Real Time Data"}
                </button>
              </div>
              <Card className={styles.iot_scrollbars}>
                <CardBody className="border">
                  <h5 className="text-center fw-normal h3 bg-white z-1">
                    Devices with Issues
                  </h5>
                  <ListGroup className="w-100  lh-base" flush>
                    {[...rooms]
                      ?.sort((a, b) => a.roomNo - b.roomNo)
                      .map((sroom) =>
                        (sroom.isAnyOffline && sroom.roomNo) ? (
                          <ListGroupItem key={sroom.roomNo} className="w-100 ">
                            <p
                              className="w-100 p-2 fw-normal h3"
                              style={{ background: "#F5F5F5" }}
                            >
                              Room {sroom.roomNo}
                            </p>
                            {sroom.data?.map(
                              (device) =>
                                device.offline && (
                                  <ul
                                    key={device.deviceId}
                                    className="lh-base p-2"
                                  >
                                    <li className="d-flex align-items-center justify-content-between">
                                      <span>
                                        {device.deviceName
                                          .trim()
                                          .toLowerCase()
                                          .includes("rm ") || device.deviceName.trim().toLowerCase().includes("room")
                                          ? device.deviceName
                                            .split(" ")
                                            .slice(2)
                                            .join(" ")
                                          : device.deviceName}
                                      </span>
                                      <div className="d-flex align-items-center text-danger justify-content-between">
                                        <CloseIcon />
                                        &nbsp;
                                        <span className="mt-1">
                                          Disconnected
                                        </span>
                                      </div>
                                    </li>
                                  </ul>
                                )
                            )}
                          </ListGroupItem>
                        ) : null
                      )}
                  </ListGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {error && (
        <h1 className="text-center">{error.message} Failed To load Data</h1>
      )}
    </>
  );
};

export default Home;
