import { Calendar, momentLocalizer } from "react-big-calendar";
// import "react-big-calendar/lib/css/react-big-calendar.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { inventoryAction, inventorySelector } from "./js/slice";
import { useSelector } from "react-redux";
import { inventoryThunk } from "./js/api";
import Loader from "../../CommonElements/Loader";
import { inventoryHelper } from "./js/helper";

const localizer = momentLocalizer(moment);
function EventCalendar({
  maxHour = 18,
  minHour = 10,
  hourIntervel = 60 * 24 - 1,
  selectedMeetingPorts = {},
}) {
  const selector = useSelector(inventorySelector);
  const [currentView, setCurrentView] = useState();
  const dispatch = useDispatch();
  const isMeetingPodCategory =
    selectedMeetingPorts.categoryName == "Meeting Pods" ? true : false;
  let tempArray = [];
  let bookingSlotCompletedArray = [];

  if (selector?.bookingCompletedSlots?.length > 0) {
    bookingSlotCompletedArray = selector?.bookingCompletedSlots.map((e) => {
      return {
        ...e,
        start: moment(e.start).toDate(),
        end: moment(e.end).toDate(),
      };
    });
  }

  if (selector?.allBookedMeetings?.length > 0) {
    tempArray = selector?.allBookedMeetings.map((e) => {
      return {
        ...e,
        start: moment(e.start).toDate(),
        end: moment(e.end).toDate(),
      };
    });
  }

  const handleSelect = ({ start, end, slots }) => {
    const setOfRoom = new Set();
    if (currentView != "day" && currentView != "week") return;
    if (moment(start).toDate().getDate() != moment(end).toDate().getDate())
      return;

    // if (minHour == start.getHours()) slots.push(end);

    const isAlreadyBooked = tempArray.filter((slot) => {
      if (
        (start >= slot.start && start < slot.end) ||
        (end > slot.start && end <= slot.end) ||
        (start <= slot.start && end >= slot.end)
      ) {
        setOfRoom.add(slot.meetingRoom);
        return slot;
      }
    });

    if (setOfRoom.size < selectedMeetingPorts?.rooms?.length) {
      if (selectedMeetingPorts?.isPrimary && setOfRoom.size > 0) {
        //  check only for it's big room.(also split as more than one)
        alert(
          "This time slot is already booked. Please choose a different time."
        );
        return;
      } else if (selectedMeetingPorts?.secondary && setOfRoom.size > 0) {
        //  check only for it's small room. (portion of a big room)
        let haveCommonElement = [...setOfRoom].some(
          (itemA) =>
            !selectedMeetingPorts?.rooms
              .map((itemB) => itemB.roomName)
              .includes(itemA)
        );
        if (haveCommonElement) {
          alert(
            "This time slot is already booked. Please choose a different time."
          );
          return;
        }
      }

      let meetingRoom = "";
      if (isAlreadyBooked.length > 0) {
        let tempRoom = selectedMeetingPorts.rooms?.filter(
          (e) => !setOfRoom.has(e.roomName) && e
        );
        if (tempRoom.length > 0) {
          meetingRoom = tempRoom[0]?.roomName;
        }
      } else {
        // for first booking -->>
        meetingRoom = selectedMeetingPorts?.rooms[0]?.roomName;
        meetingRoom = selectedMeetingPorts?.rooms[0]?.roomName;
      }

      let FormatedSlots = null;
      if (!isMeetingPodCategory) {
        //  for Napa Sonoma room / Napa or Sonoma
        // dont want slots by 15 min
        FormatedSlots = [
          `${moment(start).format("HH:mm:ss")} to ${moment(end).format(
            "HH:mm:ss"
          )}`,
        ];
      }

      dispatch(
        inventoryAction.setModalFormData({
          slots:
            FormatedSlots || inventoryHelper.generateTimeSlots(start, end, 15),
          bookedDate: moment(start).format("YYYY-MM-DD"),
          start: moment(start).startOf("minute").format("YYYY-MM-DD HH:mm:ss"),
          end: moment(end).startOf("minute").format("YYYY-MM-DD HH:mm:ss"),
          ...selectedMeetingPorts,
          meetingRoom,
        })
      );
      dispatch(inventoryAction.openModal());
    } else {
      alert(
        "This time slot is already booked. Please choose a different time."
      );
    }
  };

  // const handleEventClick = (event) => {
  //   let confirm = window.confirm("Cancel Reservation .");
  //   if (confirm) dispatch(inventoryThunk.cancelMeeting(event));
  // };

  useEffect(() => {
    dispatch(inventoryThunk.findMeeting(selectedMeetingPorts));
  }, [selectedMeetingPorts]);

  const customTimeGutterFormat = (date, culture, localizer) => {
    if (isMeetingPodCategory) {
      return localizer.format(date, "h:mm A", culture);
    } else {
      return "Full Day";
    }
  };
  const customSlotGroupGutterFormat = () => {
    if (isMeetingPodCategory) {
      return {
        className: "calendar-custom-slot-class",
        style: {
          height: selector?.selectedSlotSteps?.value + 23,
          borderBottom: "1px solid #ddd",
        },
      };
    } else {
      return {};
    }
  };

  return (
    <div
      className="big-calendar-custom"
      style={{
        height: "600px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
      }}
    >
      <Calendar
        // onSelectEvent={handleEventClick}
        onSelectSlot={handleSelect}
        localizer={localizer}
        defaultView="month"
        selectable={true}
        events={bookingSlotCompletedArray}
        style={{ height: "100% !important" }}
        step={
          isMeetingPodCategory
            ? selector?.selectedSlotSteps?.value
            : hourIntervel
        }
        timeslots={1}
        onView={(e) => setCurrentView(e)}
        eventPropGetter={() => ({
          style: {
            borderRadius: "2px",
            border: "none",
            fontSize: "11px",
          },
          // className: "calendar-custom-event-class",
        })}
        slotGroupPropGetter={customSlotGroupGutterFormat}
        formats={{
          timeGutterFormat: customTimeGutterFormat,
        }}
      />

      {selector.loader && (
        <div
          className="calenderLoader"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "#9c9c9c1a",
            zIndex: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loader />
        </div>
      )}
    </div>
  );
}

export default EventCalendar;
