import React, { Fragment } from "react";
import "./App.css";
import Routers from "./Routes";
import AnimationThemeProvider from "./_helper/AnimationTheme/AnimationThemeProvider";
import CustomizerProvider from "./_helper/customizer/CustomizerProvider";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import IntroPage from "./Pages/IntroPage/IntroPage";
import { Provider, useDispatch } from 'react-redux';
import { store } from "./app/store";
import { fetchNavbar } from "./Auth/api";
import HotelPage from "./Pages/HomePage/HotelPage";



function App() {
  if (!("Notification" in window)) {
    console.log("Browser does not support desktop notification");
  } else {
    Notification.requestPermission();
  }
  let hotelCode = localStorage.getItem("hotelcode");
  console.log('App.js >> hotelCode:', hotelCode)

  return (
    <Provider store={store}>
      <Fragment>
        <CustomizerProvider>
          <AnimationThemeProvider>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={!hotelCode ? <HotelPage /> : <Navigate to={`services`} />} />

                <Route path="/*" element={<Routers />} />
              </Routes>
            </BrowserRouter>
          </AnimationThemeProvider>
        </CustomizerProvider>
      </Fragment>
    </Provider>
  );
}
export default App;
