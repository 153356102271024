import { Image } from "react-bootstrap";
import { AxiosInterceptor, AxiosInterceptor2 } from "../utils/axios-utils";
import axios from "axios";
import { config } from "../config";

export const LoginFrontdesk = (
  email,
  password,
  setLoading,
  setLoginError,
  handleCB
) => {
  setLoading(true);
  const configurations = {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      role: "frontdesk",
    },
  };

  AxiosInterceptor.post(config.HOSPITALITY_API+"/frontdesklogin", { email, password }, configurations)
    .then((res) => {
      console.log('resresres >> ',res)
      localStorage.setItem("token", res.data?.result?.token);
      localStorage.setItem("email", res.data?.result?.email);
      // localStorage.setItem("hotelId", res.data?.result?.hotelId);
      // localStorage.setItem("hotelcode", res.data?.result?.hotelcode);
      setLoading(false);

      handleCB && handleCB({...res,jwt_token: localStorage.getItem("token")});
      return { jwt_token: localStorage.getItem("token") };
    })
    .catch((error) => {
      setLoginError(error.response?.data?.result?.message);
      setLoading(false);
    });
};

export const getMobileAppReservastions = (
  setData,
  setLoading,
  setTotalRows,
  { searchText, page, size, fieldSelected, sortSelected, inforStatus }
) => {
  setLoading(true);
  var body = {
    page: page,
    limit: size,
    searchText,
    fieldSelected, 
    sortSelected,
    inforStatus
  }
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
  };
  console.log('AxiosInterceptor2',AxiosInterceptor2.headers);
  AxiosInterceptor2.post("getAllGuestFromMobileV2", body,config)
    .then((res) => {
      res.data.result.data[0].data.map((x, index) => {
        x.isKeyGen === true ? (x.isKeyGen = "Yes") : (x.isKeyGen = "No");
        x.specialRequest = x.specialRequest || "No";
        x.isAddReservation === true
          ? (x.isAddReservation = "Yes")
          : (x.isAddReservation = "No");
      });
      setData(res.data.result.data[0].data);
      setTotalRows(res.data.result.data[0].metadata[0]?.total);
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err, "error");
    });
};
export const getReservastionDetails = (
  setData,
  setLoading,
  { reservation_id }
) => {
  setLoading(true);
  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
  };

  AxiosInterceptor2.get("getReservationDetail/"+reservation_id, config)
    .then((res) => {
      console.log(res)
      if(res.data.success){
        setData(res.data.result.data);
      }else{
        setData({error:"No data found."});
      }
      setLoading(false);
    })
    .catch((err) => {
      setLoading(false);
      console.log(err, "error");
    });
};
export const getAllCheckIn = async (
  setCheckInData,
  setLoading,

  startDate,
  endDate,
  searchText,

  setMyTotalRows,
  myCurrentPage,
  myPerPage,
  dailyCheckInFltr
) => {
  setLoading(true);
  const config = {
    params: {
      startDate,
      endDate,
      page: myCurrentPage,
      limit: myPerPage,
      searchText,
      ...dailyCheckInFltr
    },

    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
  };

  try {
    let { data } = await AxiosInterceptor2.get(`shashiReservationList`, config);
    // console.log("data.result.data:", data.result.data);

    setCheckInData(data.result.data);
    setMyTotalRows(data.result.totalCount);

    setLoading(false);
  } catch (err) {
    setLoading(false);
    console.log("err:", err);
  }
};

export const getServices = (setServices) => {
  const config = {
    params: { page: 0, limit: 10 },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
  };

  AxiosInterceptor2.get("getPendingServices", config)
    .then((res) => {
      console.log("data >>> ", res.data.result.data);
      setServices(res.data.result.data);
    })
    .catch((err) => console.log(err, "error"));
};

export const getMeetingRooms = (setMeetingRooms) => {
  const hotelId = localStorage.getItem("hotelId");
  AxiosInterceptor2.get("getmeetingroom", { headers: { hotelId } })
    .then((res) => {
      let rooms = [];
      res.data.result.data.map((room, index) => {
        if (room.roomNo.includes("POD")) {
          rooms.push({ title: `Meeting Pods (${room.roomNo})`, id: index });
        }

        if (room.roomNo.includes("Large")) {
          rooms.push({
            title: `Napa Sonama Room (${room.roomNo})`,
            id: index,
          });
        }

        if (room.roomNo.includes("Small")) {
          rooms.push({ title: `Napa or Sonama (${room.roomNo})`, id: index });
        }
      });

      setMeetingRooms(rooms);
    })
    .catch((err) => console.log(err));
};

export const getRoomFolio = (
  roomNo,
  setLoading,
  setFolio,
  setRoomNo,
  setRoomData,
  setMessage
) => {
  setLoading(true);
  const config = {
    params: {
      roomNo: roomNo,
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
    },
  };

  AxiosInterceptor2.get("getRoomFolio", config)
    .then((res) => {
      console.log("res.data.result", res.data.result);
      let {
        folio,
        totalFolioAmount,
        room: roomObj,
        reservation,
        primaryGuest,
      } = res.data.result;
      setFolio(folio);
      // setRoomNo(roomObj !== null ? roomObj.roomNo : roomNo);
      let total = 0;
      let tQty = 0;
      if (folio.length) {
        folio.forEach((item) => {
          total =
            total + (item.qty >= 1 ? item.amount * item.qty : item.amount * 1);
          tQty = tQty + item.qty;
        });
      }
      setRoomData({
        roomNo: roomObj !== null ? roomObj.roomNo : roomNo,
        reservationId: roomObj !== null ? roomObj.reservationId : "",
        email:
          roomObj !== null && roomObj.emailId !== null
            ? roomObj.emailId
            : reservation.emailId,
        folioTotal: totalFolioAmount, // parseFloat(totalFolioAmount).toFixed(2),
        folioQty: tQty,
        reservation: reservation,
        primaryGuest: primaryGuest,
      });
      setLoading(false);
    })
    .catch((err) => {
      setMessage(err.response.data.result.message);
      setFolio([]);
      setRoomData(null);
      setLoading(false);
    });
};

export const reGenerateRoomKey = async (
  setLoading2,
  roomData,
  setKeyGenSuccessMessage,
  setKeyGenErrorMessage
) => {
  setLoading2(true);
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  };

  const data = {
    emailId: roomData.email,
    hotelId: roomData.hotelId,
    reservationId: roomData.reservationId,
  };

  AxiosInterceptor2.post("reGenerateRoomKey", data, { headers: headers })
    .then((res) => {
      console.log("reGenerateRoomKey data >>", res.data);
      setLoading2(false);
      setKeyGenSuccessMessage(res.data.result.message);
    })
    .catch((err) => {
      console.log("reGenerateRoomKey error >>", JSON.stringify(err));
      setLoading2(false);
      setKeyGenErrorMessage(err.response.data.result.message);
    });
};

export const sendNotificationsToUser = async (
  roomData,
  setLoading3,
  setCustomMsg,
  message,
  setSendMessageSuccess,
  setSendMessageError
) => {
  setLoading3(true);
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
  };

  const data = {
    emailId: roomData && roomData.email,
    title: "Front desk",
    body: message,
    data: { type: "" },
  };
  AxiosInterceptor.post("sendNotificationsToUser", data, { headers: headers })
    .then((res) => {
      console.log("sendNotificationsToUser data >>", res.data);
      setLoading3(false);
      setCustomMsg("");
      setSendMessageSuccess(res.data.result.message);
    })
    .catch((err) => {
      console.log("sendNotificationsToUser error >>", JSON.stringify(err));
      setLoading3(false);
      setSendMessageError(err.response.data.result.message);
    });
};

export const fetchServices = async ({
  searchText = "",
  departmentName = "",
  packageType = "",
  requestStatus = 0,
  page = 1,
  size = 10,
  setFrontDeskServiceData,
  setData,
  setTotalRows,
  setLoading,
}) => {
  setLoading(true);

  const config = {
    params: { page: page, limit: size, searchText, departmentName, packageType, requestStatus },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
  };
  // console.log("congig >> ", config);

  try {
    const res = await AxiosInterceptor2.get("getPendingServicesV3", config);
    // console.log("data >>> ", res.data.result);
  
    const frontDeskServices = res.data.result.data.filter((x) => {
      if (x.departmentName === "Front Desk") {
        return x;
      }
    });

    // console.log(frontDeskServices, "<< frontDeskServices");
    if (frontDeskServices) {
      setFrontDeskServiceData(frontDeskServices);
    }

    const otherDepartmentSevices = res.data.result.data.filter((x) => {
      if (x.departmentName !== "Front Desk") {
        return x;
      }
    });

    // console.log(otherDepartmentSevices, "<< otherDepartmentSevices");
    if (otherDepartmentSevices) {
      setData(otherDepartmentSevices);
    }

    setTotalRows(res.data.result.count);
    setLoading(false);
  } catch (error) {
    console.log("error:", error);

    setLoading(false);
  }
};
export const fetchHistory = async ({
  searchText = "",
  departmentName = "",
  packageType = "",
  requestStatus = 0,
  page = 1,
  size = 10,
  setHistoryData,
  setData,
  setTotalRows,
  setLoading,
}) =>{
  try {
  console.log("Its doing")
  setLoading(true);


  const config = {
    params: { page: page, limit: size, searchText, departmentName, packageType, requestStatus },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "C102",
    },
  };
  const res = await AxiosInterceptor2.get("getRoomServiceRequestHistory",config); 
  setTotalRows(res.data.result.count);
  setLoading(false);
  setHistoryData(res.data.result.data)
  console.log(res.data.result.data)
  // alert(JSON.stringify(res.data.result.data))

}catch(error){
  setLoading(false);

  console.log(error)
}
}
export const testNotification = async () => {

  const config = {
    params: {  },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
  };
  // console.log("congig >> ", config);

  try {
    const res = await AxiosInterceptor2.get("test_notification", config);
  
  } catch (error) {
    console.log("error:", error);
  }
};

export const fetchFrontdeskPackageList = async ({
  setFilterData,
  setLoading,
}) => {
  setLoading(true);

  const config = {
    params: {  },
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    },
  };
  // console.log("congig >> ", config);

  try {
    const res = await AxiosInterceptor2.get("getFrontDeskPackageList", config);
    console.log("data >>> ", res.data);

    setFilterData([{packageType:"all",packageName:"All"},...res.data.result.data]);
    setLoading(false);
  } catch (error) {
    console.log("error:", error);

    setLoading(false);
  }
};



export const rejectECIorLCOServiceRequest = async ({
  requestId,
  reservationId,
  emailId,
  requestType,
}) => {
  console.log(reservationId, emailId, requestType);
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": "true",
    hotelId: localStorage.getItem("hotelId") || "c102",
  };

  console.log("headers >> ", headers);
  const res = await AxiosInterceptor2.post(
    "updateECIorLCORequest",
    { requestId, reservationId, emailId, requestType, actionType: "Reject" },
    {
      headers,
    }
  );
  console.log("data >>> ", res.data.result);
};

export const getUserById = async (_id, setUserDetails,callback) => {
  try {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": "true",
      hotelId: localStorage.getItem("hotelId") || "c102",
    };
    const res = await AxiosInterceptor2.get("getUserById", {
      headers,
      params: { _id },
    });
    if (!res?.data?.result?.data) return;
    setUserDetails(res.data.result.data);
    if(callback){ callback(null,res.data.result.data) }
    console.log("get userDetails by id >>>", res.data.result);
  } catch (error) {
    console.log("get userDetails by id error  >>>", error);
  }
};

export const changePassword = async (val, setLoading, setApiRes) => {
  try {
    setLoading(true);
    const { currentPassword, password } = val;
    let values = {
      hotelId: localStorage.getItem("hotelId") || "c102",
      email: localStorage.getItem("email"),
      currentPassword,
      password,
    };

    const res = await AxiosInterceptor2.post("changePassword", values);
    console.log(res.data, "change password api success response");
    setLoading(false);
    setApiRes({
      message: res.data?.result?.message,
      success: true,
    });
  } catch (error) {
    setLoading(false);
    setApiRes({
      message: error?.response?.data?.result?.message,
      success: false,
    });
  }
};

export const updateuserProfile = async (data,callback) => {
  try {
    const res = await AxiosInterceptor.post("frontdesk/UserProfileUpdate", data);
    console.log(res);
    callback(null,res.data)
  } catch (error) {
    callback(null,{error:true})
  }
};

export const createAccountForUser = async (setLoading, data, callback) => {
  try {
    setLoading(true);
    const res = await AxiosInterceptor.post("userRegistrationv5", data);
    callback(null, res);
    setLoading(false);
  } catch (err) {
    callback(err, null);
    setLoading(false);
  }
};

export const enrollUserInShashiCash = async (setLoading, data, callback) => {
  try {
    setLoading(true);
    const res = await AxiosInterceptor.post("updateUserLoyaltyApply", data);
    callback(null, res);
    setLoading(false);
  } catch (err) {
    callback(err, null);
    setLoading(false);
  }
};

export const settleLoyaltyAmount = async (setLoading, reservationId, token, callback) => {
  try {
    setLoading(true);
    const res = await AxiosInterceptor2.post("settleLoyalty", { reservationId: reservationId, token: token });
    callback(null, res);
    setLoading(false);
  } catch (err) {
    callback(err, null);
    setLoading(false);
  }
}


export const getIotDashboardData = async (cb) => {
  try {
    let hotelcode = localStorage.getItem("hotelcode");
    const token = localStorage.getItem("token");
    const res = await AxiosInterceptor2.get("getRoomDevicesStatusAll", {
      hotelcode: hotelcode,
      token: token,
    });
    cb(res.data);
    return res;
  } catch (err) {
    cb(err);
  }
};


export const getSpecificroomAvailability = async (
  startDate,
  endDate,
  roomType,
  roomNos,
) => {
  const body = {
    startDate,
    endDate,
    roomType,
    room: roomNos,
  };
  console.log(body);
  try {
    const res = await AxiosInterceptor2.post(
      "getSpecificRoomAvailability",
      body,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    
    console.log(res.data);
    return res.data;
  } catch (err) {
    console.log(err.msg);
  }
};

export const updateSwitchStatus = async (deviceId, newStatus) => {
  console.log(deviceId, newStatus);
  try {
    const res = await AxiosInterceptor2.post("updateSwitchStatus", {
      deviceId,
      status: newStatus,
    });
    return res;
  } catch (err) {
    console.log(err.msg);
  }
};