import { configureStore } from "@reduxjs/toolkit";
import navbarSlice from "../Auth/navbarSlice";
import inventorySlice from "../Component/InventoryManagement/js/slice";
import pagesSlice from "../Component/MobileAppReservations/pagesSlice";
import profile360Slice from "../Component/Profile360/js/slice";
export const store = configureStore({
  reducer: {
    fetchNavbar: navbarSlice,
    ManageInventory: inventorySlice,
    pagesSlice: pagesSlice,
    ManageProfile360: profile360Slice,
  },
});
