import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInterceptor, AxiosInterceptor2 } from "../utils/axios-utils";
import axios from "axios";
import { config } from "../config";
import { profile360_actions } from "../Component/Profile360/js/slice";


export const fetchNavbar = createAsyncThunk(
  "frontdeskProfile",
  async (state, { getState, rejectWithValue, dispatch }) => {
    var token = localStorage.getItem("token");

    try {
      let { data } = await AxiosInterceptor.get(`${config.HOSPITALITY_API}/frontdeskProfile`, {headers: {authorization:token}});
      console.log("data:", data.result.data);
      // localStorage.setItem("hotelcode", data.result.data.hotelCode);
      dispatch(profile360_actions.setHotelData(data.result.data))
      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
