import { inventoryThunk } from "./api";

export const extraReducers = (builder) => {
  builder.addCase(inventoryThunk.allMeetingCategroy.pending, (state) => {
    console.log("allMeetingCategroy Pending");
  });
  builder.addCase(
    inventoryThunk.allMeetingCategroy.fulfilled,
    (state, action) => {
      console.log("allMeetingCategroy fulfilled");
      state.meetingCategory = action?.payload;
    }
  );
  builder.addCase(inventoryThunk.allMeetingCategroy.rejected, (state) => {
    console.log("allMeetingCategroy rejected");
    state.meetingCategory = [];
  });

  //<< ---------------------------------- SECTION ------------------------------- >>

  builder.addCase(inventoryThunk.createNewMeeting.pending, (state) => {
    console.log("createNewMeeting Pending");
    state.loader = true;
  });
  builder.addCase(inventoryThunk.createNewMeeting.fulfilled, (state) => {
    console.log("createNewMeeting fulfilled");
  });
  builder.addCase(inventoryThunk.createNewMeeting.rejected, (state) => {
    console.log(
      "createNewMeeting rejected need to set alert for booking cancel"
    );
    state.loader = false;
  });

  //<< ---------------------------------- SECTION ------------------------------- >>

  builder.addCase(inventoryThunk.findMeeting.pending, (state) => {
    state.loader = true;
    console.log(".findMeeting Pending");
  });
  
  builder.addCase(inventoryThunk.findMeeting.fulfilled, (state, action) => {
    state.allBookedMeetings = action.payload.data;
    state.bookingCompletedSlots = action.payload.bookingCompletedSlots;
    state.isBookedMeetingsAvailable = true;
    state.loader = false;
    console.log(".findMeeting fulfilled");
  });

  builder.addCase(inventoryThunk.findMeeting.rejected, (state) => {
    state.loader = false;
    state.allBookedMeetings = [];
    state.bookingCompletedSlots = [];
    state.isBookedMeetingsAvailable = false;
    console.log(".findMeeting rejected");
  });

  builder.addCase(inventoryThunk.cancelMeeting.pending, (state) => {
    state.loader = true;
    console.log(".cancelMeeting Pending");
  });

  builder.addCase(inventoryThunk.cancelMeeting.fulfilled, (state, action) => {
    console.log(".cancelMeeting fulfilled");
  });
  
  builder.addCase(inventoryThunk.cancelMeeting.rejected, (state) => {
    state.loader = false;
    console.log(".cancelMeeting rejected");
  });
};
