import React from 'react';
import { H6 , Badges } from '../../AbstractElements';
import moment from 'moment';
import { Button } from 'reactstrap';
import { rejectECIorLCOServiceRequest } from '../../actions/axios-actions';

const getDescText = (requests) => {
    if(requests.note) {
      return requests.note;
    } else if(requests.selectedTime) {
      return requests.selectedTime;
    } else {
      return requests.Item
    }

	};

  const convertSecToTime = (t) => {
    let hours = Math.floor(t / 3600);
    let hh = hours < 10 ? "0" + (hours).toString()
        : (hours).toString();
    let min = Math.floor((t % 3600) / 60);
    let mm = min < 10 ? "0" + (min).toString()
        : (min).toString();
    let sec = ((t % 3600) % 60);
    let ss = sec < 10 ? "0" + (sec).toString()
        : (sec).toString();
    let ans = hh + ":" + mm + ":" + ss;
    return ans;
}

const getElapsedNow = (createdAt) => {
  let elapsedNow
  let createdMoment = moment(parseInt(createdAt));
  let todayDate = moment(); 
  let timeElapsed = moment.duration(todayDate.diff(moment.unix(parseInt(createdAt)/1000)));
  let timeElapsedInDays = Math.abs(timeElapsed.asDays()).toFixed();
  if(timeElapsedInDays > 0) {

    elapsedNow = `${Math.abs(timeElapsedInDays).toFixed(0)} days ago`
  } else {
    let t1 = parseInt(parseInt(createdAt)/1000);
    let t2 = moment().unix(); 

    let time_diff
               = (t1 - t2 < 0) ? t2 - t1 : t1 - t2;
    
      elapsedNow =  convertSecToTime(time_diff)

    // setServices((prevState) => ({
    //   ...prevState,
    //   elapsedNow: convertSecToTime(time_diff)
    // }));
  }
  return elapsedNow
}  

const styleObj = {
  marginTop:'20px'
}
const rejectServiceRequest = async (e, requestId, reservationId, emailId, requestType) => {
  e.preventDefault();
  console.log(e, "<< e");
  console.log(reservationId, emailId, requestType);
  rejectECIorLCOServiceRequest({requestId, reservationId, emailId, requestType});
}

export const frontDeskServiceColumns = [
  {
    name: <H6>Name</H6>,
    selector: (row) => {
      if (row.guestName.length === 0) return ""; // Handle empty string
      console.log(row.guestName);
      if(row.guestName){
        let guestName = row.guestName;
        let returnText = guestName.split(" ").map((e) => {
          return e.charAt(0).toUpperCase() + e.slice(1).toLowerCase();
        });
        console.log(returnText.join(" "));
        return returnText.join(" ");
      }else{
        console.log(row.guestName);
        return "-"
      }
    },
    sortable: true,
    // center: true,
    wrap: true,
  },
  {
    name: <H6>Email ID</H6>,
    selector: (row) => row.emailId || "-",
    sortable: true,
    // center: true,
    wrap: true,
  },
  {
    name: <H6>Arrival Date</H6>,
    selector: (row) => row.arrivalDate || "-",
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Departure Date</H6>,
    selector: (row) => row.departureDate || "-",
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Reservation ID</H6>,
    selector: (row) => row.reservationId || "-",
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Requested</H6>,
    selector: (row) => row.requestTime || "-",
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Request Name</H6>,
    selector: (row) => <div className='text-center'>{row.packageName || "-"}</div>,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Request Status</H6>,
    selector: (row) =>
      row.requestStatus ? (
        <div className="text-end" style={{ fontSize: "15px" }}>
          <Badges
            attrBadge={{
              color: "success",
              style: { width: "85px", paddingTop: "6px", paddingBottom: "6px",borderRadius:'0px' },
            }}
          >
            Approved
          </Badges>
        </div>
      ) : (
        <div className="text-end" style={{ fontSize: "15px" }}>
          <Badges
            attrBadge={{
              color: "warning",
              style: { width: "85px", paddingTop: "6px", paddingBottom: "6px",borderRadius:'0px'  },
            }}
          >
            Rejected
          </Badges>
        </div>
      ),
    sortable: true,
    center: true,
    wrap: true,
  },
];

export const  otherDepartmentServiceColumns = [
  {
    name: <H6>Room No</H6>,
    selector: (row) => row.roomNo,
    sortable: true,
    center: true,
    wrap: true,
  },  
  {
    name: <H6>Reservation ID</H6>,
    selector: (row) => row.reservationId,
    sortable: true,
    center: true,
    wrap: true,
  },
  // {
  //   name: <H6>DepartmentS Name</H6>,
  //   selector: (row) => row.departmentName || '-',
  //   sortable: true,
  //   center: true,
  //   wrap: true
  // },
  {
    name: <H6>Item</H6>,
    selector: (row) => row?.viewtype ? row.viewtype == "room_preference" ? "Room Preference" : row.viewtype == "minibar" ? "Mini Bar": row.requests?.[0].title : row.requests?.[0].title,
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Description</H6>,
    selector: (row) => row.requests?.length ? (row.requests[0]?.note || row.requests[0]?.name || row.requests[0]?.title) + (row.requests?.length > 1 ? (" +" + (row.requests?.length - 1)) : " ") : "-",
    // [0]?.title?.toLowerCase().includes("tea") ? row.requests?.[0]?.time : getDescText(row.requests[0]) || '-',
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Qty</H6>,
    // selector: (row) => row.requests[0].quantity || 0,
    selector: (row) => row.requests?.reduce((acc,curr)=> acc + curr.quantity, 0) || "-",
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Price/Item</H6>,
    selector: (row) => row?.viewtype === "minibar" ? 
    ((row.requests.length === 0) ? '$ 0.00' : '$ ' + (row.requests.reduce((acc, request) => {
      if (request.price !== undefined && request.quantity !== undefined  && request.quantity !== "" && request.price != "") {
          return acc + (request.price * request.quantity);
      }
      return acc;
  }, 0)).toFixed(2)) 
    : parseFloat(row.requests[0]?.price) > 0 ? "$ " + parseFloat(row.requests[0]?.price).toFixed(2): "$ 0.00",
    sortable: true,
    center: true, 
  },
  {
    name: <H6>Total</H6>,
    selector: (row) => {
      if (row.requests.length === 0) {
          return '$ 0.00';
      }
      
      const total = row.requests.reduce((acc, request) => {
          if (request.price !== undefined && request.quantity !== undefined) {
              return acc + (request.price * request.quantity);
          }
          return acc;
      }, 0);
      
      return '$ ' + total.toFixed(2);
  },
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Date</H6>,
    selector: (row) => {
      // if(row.createdAt.length === 10){
      //   row.createdAt = `${row.createdAt}000`
      // }
      // return moment.unix(parseInt(row.createdAt)/1000).format('MMM DD, YYYY HH:mm')

      return row.createdAt
    },
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Time Elapsed</H6>,
    selector: (row) => row.elapsedTime,
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Status</H6>,
    selector: (row) => row.status == "pending" ?
    (
      <div className="text-end" style={{fontSize:"15px"}} >
        <Badges attrBadge={{ color: 'warning' ,style:{borderRadius:'0px'} }}>Pending</Badges>
      </div>
    )
    :
    (
      <div  className="text-end" style={{fontSize:"15px"}}>
        <Badges attrBadge={{ color: 'success' ,style:{borderRadius:'0px'}}}>Assigned To <br/>"{row.assignedToName}"</Badges>
      </div>
    ),
    sortable: true,
    center: true,
    wrap: true
  },
];


export const HistoryColumns = [
  
  {
    name: <H6>Room No</H6>,
    selector: (row) => row.roomNo,
    sortable: true,
    center: true,
    wrap: true,
  },  
  {
    name: <H6>Reservation ID</H6>,
    selector: (row) => row.reservationId,
    sortable: true,
    center: true,
    wrap: true,
  },
  // {
  //   name: <H6>Department Name</H6>,
  //   selector: (row) => row.departmentName || '-',
  //   sortable: true,
  //   center: true,
  //   wrap: true
  // },
  {
    name: <H6>Item</H6>,
    selector: (row) => row?.viewtype ? row.viewtype == "room_preference" ? "Room Preference" : row.viewtype == "minibar" ? "Mini Bar": row.requests?.[0].title : row.requests?.[0].title,
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Description</H6>,
    selector: (row) => row.requests?.length ? (row.requests[0]?.note || row.requests[0]?.name || row.requests[0]?.title) + (row.requests?.length  > 1 ? (" +" + (row.requests?.length - 1)) : " ") : "-",
    // [0]?.title?.toLowerCase().includes("tea") ? row.requests?.[0]?.time : getDescText(row.requests[0]) || '-',
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Qty</H6>,
    // selector: (row) => row.requests[0].quantity || 0,
    selector: (row) => row.requests?.reduce((acc,curr)=> acc + curr.quantity, 0) || "-",
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Price/Item</H6>,
    selector: (row) => row?.viewtype === "minibar" ? 
    ((row.requests.length === 0) ? '$ 0.00' : '$ ' + (row.requests.reduce((acc, request) => {
      if (request.price !== undefined && request.quantity !== undefined) {
          return acc + (request.price * 1);
      }
      return acc;
  }, 0)).toFixed(2)) 
    :  parseFloat(row.requests[0]?.price) > 0 ? "$ " + parseFloat(row.requests[0]?.price).toFixed(2): "$ 0.00",
    sortable: true,
    center: true, 
  },
  {
    name: <H6>Total</H6>,
    selector: (row) => {
      if (row.requests.length === 0) {
          return '$ 0.00';
      }
      
      const total = row.requests.reduce((acc, request) => {
          if (request.price !== undefined && request.quantity !== undefined) {
              return acc + (request.price * 1);
          }
          return acc;
      }, 0);
      
      return '$ ' + total.toFixed(2);
  },
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Date</H6>,
    selector: (row) => {
      // if(row.createdAt.length === 10){
      //   row.createdAt = `${row.createdAt}000`
      // }
      return moment.unix(parseInt(row.createdAt)/1000).format('MMM DD, YYYY HH:mm')
      // return row.createdAt
    },
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Time Elapsed</H6>,
    selector: (row) => row.elapsedTime,
    sortable: true,
    center: true,
    wrap: true
  },
  {
    name: <H6>Status</H6>,
    // completed", "cancelled"
    selector: (row) => row.status == "cancelled" ?
    (
      <div className="text-end" style={{fontSize:"15px"}} >
        <Badges attrBadge={{ color: 'danger' ,style:{borderRadius:'0px'} }}>Cancelled</Badges>
      </div>
    )
    : 
    (
      <div  className="text-end" style={{fontSize:"15px"}}>
        <Badges attrBadge={{ color: 'success' ,style:{borderRadius:'0px'}}}>Completed <br/></Badges>
      </div>
    ),
    sortable: true,
    center: true,
    wrap: true
  },
];

// 