export function rgbToHex(r, g, b) {
  // Ensure that the values are within the valid range (0 to 255)
  function clamp(value) {
    return Math.min(255, Math.max(0, value));
  }

  function hex(value) {
    const hexValue = value.toString(16);
    return hexValue.length === 1 ? "0" + hexValue : hexValue;
  }

  return `#${hex(clamp(r))}${hex(clamp(g))}${hex(clamp(b))}`;
}