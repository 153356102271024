export const initialState = {
  selectedMeetingCategory: false,
  selectedSlotSteps: { label: "1 hour", value: 60 },
  availSlotSteps: [
    { label: "15 minute", value: 15 },
    { label: "30 minute", value: 30 },
    { label: "45 minute", value: 45 },
    { label: "1 hour", value: 60 },
  ],
  modal: false,
  formData: {
    title: "",
    email: "",
    categoryName: "",
    meetingRoom: "",
    hotelId: "",
    start: "",
    end: "",
    slots: [],
    bookedDate: "",
  },
  meetingCategory: [],
  allBookedMeetings: [],
  bookingCompletedSlots: [],
  isBookedMeetingsAvailable: false,
  loader: false,
};
