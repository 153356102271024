import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Btn } from '../../AbstractElements';

export const LoyaltySettleConfirmation = ({
  isOpen,
  toggle,
  settleLoyalty
}) => {
  const confirmSettlement = async () => {
   toggle();
   await settleLoyalty();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>
        Loyalty Settlement Confirmation
      </ModalHeader>
      <ModalBody className="text-black">
        Do you want to settle this loyalty ?
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ className: "rounded-0", color: "dark", outline: true, onClick: toggle }} >No</Btn>
        <Btn attrBtn={{ className: "rounded-0 confirm-btn", color: 'primary', onClick: confirmSettlement }}>Yes</Btn>
      </ModalFooter>
      <style jsx>
        {`
          .confirm-btn:hover {
            background-color: #ff0094 !important;
          }
        `}
      </style>
    </Modal>
  );
}