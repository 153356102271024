import { createSelector, createSlice } from "@reduxjs/toolkit";
import { extraReducers } from "./extraReducers";
import { initialState } from "./initialState";
import { reducers } from "./reducers";

// create new slice for inventory manangement ( meeting sections)-->>
const inventorySlice = createSlice({
  name: "ManageInventory",
  initialState,
  reducers,
  extraReducers,
});

export default inventorySlice.reducer;
export const inventoryAction = inventorySlice.actions;

export const inventorySelector = (state) => state.ManageInventory;

export const meetingCategorySelector = createSelector(
  [inventorySelector],
  (state) => state.meetingCategory
);

export const selectedMeetingCategorySelector = createSelector(
  [inventorySelector],
  (state) => state.selectedMeetingCategory
);

export const selectedSlotStepsSelector = createSelector(
  [inventorySelector],
  (state) => state.selectedSlotSteps
);

export const availSlotStepsSelector = createSelector(
  [inventorySelector],
  (state) => state.availSlotSteps
);
